import React, { useContext, useEffect, useState } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalHeader,
	ModalTitle,
} from '../../../../../components/bootstrap/Modal';
import { SubHeaderLeft } from '../../../../../layout/SubHeader/SubHeader';
import Wizard, { WizardItem } from '../../../../../components/Wizard';
import ImportFirstStep from './components/importFirstStep/importFirstStep.component';
import { useImportFromLeghe } from '../../../../../hooks/businessLogic/useImportFromLeghe';
import { ImportedLeague, ImportedTeam } from '../../../../../api/league/league.model';
import ImportSecondStep from './components/importSecondStep/importSecondStep.component';
import { ToastContext } from '../../../../../contexts/toastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { LeagueContext } from '../../../../../contexts/leagueContext';
import { addImportedLeague, getLeagueTeams } from '../../../../../api/league/league.api';
import { importLeague } from '../../../../../api/league/league.api';
import { init } from 'react-facebook-pixel';
import { updateTeams } from '../../../../../api/team/team.api';

interface ImportFromLegheProps {
	showImportFlow: boolean;
	setShowImportFlow: (show: boolean) => void;
	action: string | undefined;
	teams: any;
	setTeams: (teams: any) => void;
}

const ImportFromLeghe = (props: ImportFromLegheProps) => {
	const selectedLeague = useContext(LeagueContext)!;
	const navigate = useNavigate();
	const { action } = useParams();
	const { setToast } = useContext(ToastContext);
	const [myLeague, setMyLeague] = useState<string>('');
	const [competition, setCompetition] = useState<ImportedLeague>();
	const [team, setTeam] = useState<any>({ id: '', name: '', initialCredits: 0 });

	const { teams, setTeams } = props;
	const [importedLeague, setImportedLeague] = useState<any>();
	const [leagueImportSuccess, setLeagueImportSuccess] = useState<boolean>(false);

	const isResult = (action: string | undefined) => Boolean(action?.includes('importa'));

	const showImportFlow = isResult(props.action);

	const setShowImportFlow = (open: boolean) => {
		const newAction =
			(props.action
				? props.action
						.split('-')
						.filter((a) => a !== 'importa')
						.join('-')
				: '') + (open ? '-importa' : '');
		navigate(`/setup-lega/${newAction}`);
	};

	useEffect(() => {
		if (selectedLeague.selectedLeague) {
			getLeagueTeams(selectedLeague.selectedLeague.id).then((res) => {
				setTeams(res.teams);
			});
		}
	}, [selectedLeague]);

	const addTeam = () => {
		if (team && teams) {
			const teamIndex = teams.findIndex(
				(teamItem: any) => team.id === teamItem.id && team.id !== '',
			);
			if (teamIndex !== -1) {
				// Team with the same id found, do nothing
			} else {
				// No team with the same id found, add the new team
				if (selectedLeague) {
					const newTeamsArray = teams.concat(team);
					updateTeams(selectedLeague.selectedLeague.id, newTeamsArray);
					setTeams(newTeamsArray);
					if (competition?.id) {
						addImportedLeague(selectedLeague.selectedLeague.id, competition?.leagueAlias, competition.id, team.externalId, competition?.leagueIdExt);
					} else {
						console.error('ID della competizione non definito.');
					}
				}
			}
			setTeam({ id: '', name: '', initialCredits: 0 });
		}
		setShowImportFlow(false);
	};

	function handleLeagueImport() {
		if (selectedLeague.selectedLeague.id) {
			importLeague(selectedLeague.selectedLeague.id, myLeague)
				.then((ret) => {
					setImportedLeague(ret);
					setLeagueImportSuccess(true);
				})
				.catch((error) => {
					setLeagueImportSuccess(false);
					console.error(error); //TODO: show error message
				});
		}
	}

	return (
		<>
			<SubHeaderLeft>
				<Button
					color='warning'
					icon='Upload'
					isDisable={false}
					onClick={() => {
						setMyLeague('');
						setShowImportFlow(true);
					}}>
					Importa da Leghe Fantacalcio
				</Button>
			</SubHeaderLeft>
			<Modal
				isOpen={showImportFlow}
				setIsOpen={setShowImportFlow}
				isCentered={true}
				isAnimation={true}>
				<ModalHeader showClose={false} className={''} setIsOpen={setShowImportFlow}>
					<ModalTitle id={''}>Importa da Leghe Fantacalcio</ModalTitle>
					<button
						type='button'
						className='btn-close'
						data-bs-dismiss='modal'
						aria-label='Close'
						onClick={() => {
							setShowImportFlow(false);
						}}
					/>
				</ModalHeader>
				<ModalBody className={''}>
					<Wizard
						isHeader={false}
						className={'shadow-none mb-0'}
						customAction0={handleLeagueImport}
						onSubmit={addTeam}
						hideprevbutton='true'>
						<WizardItem id={'first-step'}>
							<ImportFirstStep myLeague={myLeague} setMyLeague={setMyLeague} />
						</WizardItem>
						<WizardItem id={'second-step'}>
							<ImportSecondStep
								competition={competition}
								setCompetition={setCompetition}
								team={team}
								setTeam={setTeam}
								leagueImportSuccess={leagueImportSuccess}
								importedLeague={importedLeague}
							/>
						</WizardItem>
					</Wizard>
				</ModalBody>
			</Modal>
		</>
	);
};

export default ImportFromLeghe;
